<template>
  <div class="projects" style="padding-right:0px !important">
  <main class="mt-0 main-content main-content-bg">
    
    <section>
      <img src='@/assets/img/RedPillBot.png' style="position:absolute; bottom:0; margin-right: 0px;">
      <!-- <div
               
                style="position: absolute; width:100%; height: 100vh; background-color: red; top:0; bottom: 0; right:0; padding:0px;"
              >
              
              </div> -->
      <div class="page-header min-vh-75">
        <div class="container" style="background-color:rgba(0,0,0,0);!important">
          <div class="row">
            <div v-if="registerActive =='login'" class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain" >
                <div class="pb-0 card-header text-start" style="background-color:rgba(0,0,0,0);!important">
                  <h3 class="font-weight-bolder text-gored text-gradient">
                    Welcome back
                  </h3>
                  <p class="mb-0">Enter your email and password</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="login">
                  
                    <label>Email</label>
                    <input v-model="emailLogin" type="email" class="form-control" placeholder="Email" required>
                    <label>Password</label>
                    <input v-model="passwordLogin" type="password" class="form-control" placeholder="Password" required>

                    <div class="text-center">
                      <soft-button class="my-4 mb-2" color="gored" full-width >Sign in </soft-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <a href="#" @click="registerActive = 'register', emptyFields = false" class="text-gored text-gradient font-weight-bold">Sign Up</a>
                  </p>
                  <p class="mx-auto mb-4 text-sm">
                    Forgot your password?
                    <a href="#" @click="registerActive = 'resetPassword', emptyFields = false" class="text-gored text-gradient font-weight-bold">Reset Password</a>
                  </p>
                </div>
              </div>
            </div>
            <div v-else-if="registerActive =='resetPassword'" class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start" style="background-color:rgba(0,0,0,0);!important">
                  <h3 class="font-weight-bolder text-gored text-gradient">
                    Forgot your password?
                  </h3>
                  <p class="mb-0">Enter your email to reset password</p>
                  <div class="card-body">
                    <form class="form-group"  @submit.prevent="forgot" >
                      <input v-model="emailLogin" type="email" class="form-control" placeholder="Email" required>
                      <!-- <input type="submit" class="btn btn-primary" value="Send Rest Code"> -->
                      <div class="text-center">
                        <soft-button class="my-4 mb-2" color="gored" full-width >Send Rest Code</soft-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="registerActive =='newPassword'" class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start" style="background-color:rgba(0,0,0,0);!important">
                  <h3 class="font-weight-bolder text-gored text-gradient">
                    New Password Required
                  </h3>
                  <p class="mb-0">Enter new password</p>
                  <div >
                    <form class="text-start"  @submit.prevent="newPassword" >
                      <label>New Password</label>
                      <input v-model="passwordLogin" type="password" class="form-control" placeholder="Password" required >
                      <label>Confirm Password</label>
                      <input v-model="passwordConfirm" type="password" class="form-control" placeholder="Password" required v-on:blur="validate">
                      <small class="text-gored" v-if="showPasswordError">Passwords don't match!</small>
                      <div class="text-center">
                        <soft-button class="my-4 mb-2" color="gored" full-width >Set New Password</soft-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="registerActive =='resetPassword2'" class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start" style="background-color:rgba(0,0,0,0);!important">
                  <h3 class="font-weight-bolder text-gored text-gradient">
                    Check your email for verification code
                  </h3>
                  <p class="mb-0">Enter verification code and new password</p>
                  <div class="card-body">
                    <form role="form" class="text-start" @submit.prevent="resetPassword">
                      <input v-model="authCode" type="number" class="form-control" placeholder="Code" required>
                      <input v-model="emailLogin" type="email" class="form-control" placeholder="Email" required>
                      <input v-model="passwordLogin" type="password" class="form-control" placeholder="Password" required name=up>
                      <input v-model="confirmReg" type="password" class="form-control" placeholder="Confirm Password" required name=up2 oninput='up2.setCustomValidity(up2.value!=up.value ? "Password do not match." : "")'>
                      <div class="text-center">
                        <soft-button class="my-4 mb-2" color="gored" full-width >Reset</soft-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="registerActive =='register'" class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start" style="background-color:rgba(0,0,0,0);!important">
                  <h3 class="font-weight-bolder text-gored text-gradient">
                    Sign Up
                  </h3>
                  <p class="mb-0">Enter your email and password</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="register">
                  
                    <label>Email</label>
                    <input v-model="emailLogin" type="email" class="form-control" placeholder="Email" required>
                    <label>Password</label>
                    <input v-model="passwordLogin" type="password" class="form-control" placeholder="Password" required name=up>
                    <label>Confirm Password</label>
                    <div class="form-group">
                      <input v-model="confirmReg" type="password" class="form-control"  placeholder="Confirm Password" required name=up2 oninput='up2.setCustomValidity(up2.value!=up.value ? "Password do not match." : "")'>
                    </div>
                      <div class="text-center">
                        <soft-button
                          class="my-4 mb-2"
                          color="gored"
                          full-width
                          >Register
                      </soft-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Already a member?
                    <a href="#" @click="registerActive = 'login', emptyFields = false" class="text-gored text-gradient font-weight-bold">Sign In</a>
                  </p>
                </div>
              </div>
            </div>
            <div v-if="registerActive =='confirmSignUp'" class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start" style="background-color:rgba(0,0,0,0);!important">
                  <h3 class="font-weight-bolder text-gored text-gradient">
                    Confirm
                  </h3>
                  <p class="mb-0">Check your email for a confirmation code</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="confirmSignUp">
                  
                    <label>Code</label>
                    <input v-model="authCode" type="number" class="form-control" placeholder="Code" required>
                   
                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        color="gored"
                        full-width
                        >Confirm
                      </soft-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Can't find the code?
                    <a href="#" @click="resendConfirmationCode" class="text-gored text-gradient font-weight-bold">Resend Code</a>
                  </p>
                </div>
              </div>    
            </div>
            <div class="col-md-4">
              
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
  <!-- <app-footer /> -->
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import { Amplify } from 'aws-amplify';
Amplify.configure({
    Auth: {
        // ...
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
});
import { Auth } from 'aws-amplify';
import router from '../router'
export default {
  name: "SignIn",
  components: {
    SoftButton,
  },
  data(){
    return {
      registerActive: "login",
      emailLogin: "",
      passwordLogin: "",
      passwordConfirm: "",
      loading: false,
      confirmReg: '',
      authCode:'',
      showPasswordError: false,
      user: undefined,
    }
  },
  watch: {
		loading: function (loading) {
			if (loading) 
			{
				this.loader = this.$loading.show({container: this.$refs.loadingContainer});
			} else if(this.loader)
			{
				this.loader.hide();
				this.loader = null;
			}
		}
	},
  created() {
    //this.toggleEveryDisplay();
    //this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
     //this.toggleEveryDisplay();
     //this.toggleHideConfig();
     body.classList.add("bg-gray-100");

  },
  methods: {
     ...mapMutations(["toggleEveryDisplay", "toggleHideConfig", "setLoggedIn"]),
     validate: function() 
     {
       
        if(this.passwordLogin!=this.passwordConfirm)
          this.showPasswordError = true;
        else
          this.showPasswordError = false;
    },
    async login() 
    {
      this.loading = true;
      Auth.signIn(this.emailLogin, this.passwordLogin).then( user=> {

        if(user.challengeName!=undefined && user.challengeName=="NEW_PASSWORD_REQUIRED")
        {
          this.user = user;
          this.logged = false;
          this.loading = false;
          if(this.loader)
          {
            this.loader.hide();
            this.loader = null;
          }
          this.passwordLogin = "";
          this.registerActive = "newPassword";
          return;
        }
        this.logged = true;
        this.loading = false;
        if(this.loader)
        {
          this.loader.hide();
          this.loader = null;
        }
        user;
        console.log('signed user', JSON.stringify(user));
        this.setLoggedIn(true);
        router.push({ path: '/'});
        //router.go();
          
      })
      .catch(error=>
      {
        this.loading = false;
        this.setLoggedIn(false);
        console.log('error signing in', error.code);
        if(error.code==="UserNotConfirmedException")
            this.registerActive ='confirmSignUp';
        else
        {
            alert(error.message);
        }
      })
    },
    async register() 
    {
        this.loading = true;
        try {
            await Auth.signUp({
              username: this.emailLogin,
              password: this.passwordLogin,
            });
            //alert('User successfully registered. Please login');
            this.registerActive ='confirmSignUp';
        } catch (error) {
            this.loading = false;
            alert(error.message);
        }
        this.loading = false;
    },
    async resendConfirmationCode() {
        this.loading = true;
        try {
            await Auth.resendSignUp(this.emailLogin);
            console.log('code resent successfully');
        } catch (err) {
            console.log('error resending code: ', err);
        }
        this.loading = false;
    },
    async confirmSignUp() {
            this.loading = true;
            Auth.confirmSignUp(this.emailLogin, this.authCode, {
                forceAliasCreation: true    
            }).then(data => {
                //console.log(data);
                data;
                this.loading = false;
                this.registerActive ='login';
            })
            .catch(err => {
                console.log(err);
                this.loading = false;
                });
           
    },
    async newPassword()
    {
      if(this.passwordLogin === this.passwordConfirm && this.passwordLogin!="")
      {
        this.loading = true;
        Auth.completeNewPassword(this.user,this.passwordLogin ).then((user) => {
          this.loading = false;
          this.registerActive ='login';
          user;
        }).catch(err => {
            console.log(err);
            this.loading = false;
          });

      }
     
    },
    async forgot()
  {
      this.loading = true;
      Auth.forgotPassword(this.emailLogin)
          .then(data => 
              {
                  console.log(data);
                  this.passwordLogin=''
                  this.registerActive ='resetPassword2';
                  this.loading = false;
              })
          .catch(err => {console.log(err);this.loading = false;});
      
  },
  async resetPassword()
  {
    this.loading = true;
    Auth.forgotPasswordSubmit(this.emailLogin, this.authCode, this.passwordLogin)
    .then(data => {
            console.log(data);
            this.registerActive ='login';
            this.$root.$emit('check_login')
            this.loading = false;
            //router.push({ path: '/login', replace: true })
            //router.go();
        })
    .catch(err => {
            console.log(err)
            this.loading = false;
            alert(err.message);
            this.loading = false;
        });
    
  },
  },
  
  
};
</script>
<style scoped>
img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: auto; /* to keep proportions */
  height: auto; /* to keep proportions */
  max-width: 100%; /* not to stand out from div */
  max-height: 100%; /* not to stand out from div */
  margin: auto auto 0; /* position to bottom and center */
}
</style>